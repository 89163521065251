import {swipers as modalSwipers} from '../_layout/slider';
import Modal from 'bootstrap/js/src/modal';

const modalSliders = document.querySelectorAll('[id^=\'block-gallery-\']');

modalSliders.forEach((modalSlider) => {
    const gallery = modalSlider.querySelector('.block-gallery-images');
    const images = gallery.querySelectorAll('.block-gallery-image');
    const modalContainer = modalSlider.querySelector('.modal');
    const modal = new Modal(modalContainer);
    const innerSwiper = modalSwipers.find((modalSwiper) => modalContainer.contains(modalSwiper.el));

    if (!innerSwiper) {
        console.error('Swiper instance not found for modal:', modalContainer);
        return;
    }

    images.forEach((image) => {
        image.addEventListener('click', () => {
            const imageId = image.dataset.imageId;
            const swiperImage = modalSlider.querySelector(`.swiper-slide[data-image-id='${imageId}']`);
            if (!swiperImage) {
                console.error(`Swiper image with ID ${imageId} not found`);
                return;
            }

            let parsedIndex = parseInt(swiperImage.dataset.swiperSlideIndex, 10);
            if (isNaN(parsedIndex)) {
                console.error(`Invalid swiper index for image ID ${imageId}`);
                return;
            }

            innerSwiper.slideTo(parsedIndex + 1);
            modal.show();
        });
    });
});
